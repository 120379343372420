import '../../assets/css/tema.scss';
import "./style.scss";

import React, {useRef, useState} from "react";
import {Button, Col, Row, Space} from "antd";
import {
    notificacaoErroRequest,
    notificacaoErroValidForm,
    PADRAO_MARGEM_POPUP,
    useGridWidth,
    useWindowSize
} from "../../lib/iy2b-react";
import IY2BReactModal from "../IY2BReactModal";
import {ProForm} from "@ant-design/pro-components";
import {ProFormText} from "@ant-design/pro-form";
import IY2BProFormMasked from "../IY2BProFormMasked";
import IY2BProFormFiller from "../proform/IY2BProFormFiller/iy2bProFormFiller";
import IY2BProFormButton from "../proform/IY2BProFormButton/proFormButton";
import {notificacao} from "../../lib/Notificacao";
import {solicitarEnvioToken, validarTokenDigitado} from "../../lib/LoginAPI";
import {postWS} from "../../lib/RequestAPI";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../../pages/Workspace/workspace";

const PopUpLoginComToken = ( props ) => {

    const { exibePopUp, onRequestClose, cdTransacao=0, onLogin } = props;

    const windowSize = useWindowSize();
    const [ , gridWidth ] = useGridWidth();

    const MARGIN_LEFT = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.widthMargin(PADRAO_MARGEM_POPUP.XXL);
    const MODAL_CONTENT_WIDTH = (windowSize.width - (MARGIN_LEFT*2)) ;
    const MODAL_CONTENT_TOP = (gridWidth.current.compare(window._SM_)<=0) ? 10 : windowSize.heightMargin(PADRAO_MARGEM_POPUP.XXL);

    const modalRefApi = useRef();
    const formRef = useRef();

    const popupTitle = "Login por token";

    const inputRefTelCel = useRef();
    const inputRefToken = useRef();

    const [etapaAtual, setEtapaAtual] = useState("TELEFONE");

    const telCelPatterns = [{
        pattern: "(00) 0-0000-0000",
        length: 11, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const tokenPatterns = [{
        pattern: "000000",
        length: 6, // sem mascara
        validator: (value) => {
            return {valido:true, message:"mensagem de erro qdo for invalido"};
        }
    }];

    const validaTela = async (params) => {

        const {exibeMens = true} = params || { };

        const ffValues = formRef.current?.getFieldsValue();

        return true;

    };

    const doSolitarToken = async () => {

        try {

            const ffValues = formRef.current?.getFieldsValue();

            const telefone = ffValues.nrTelCelular.retiraCaracteres("() -");

            const resultado = await solicitarEnvioToken(telefone);

            if(resultado.data?.erro === true) {
                notificacaoErroRequest(resultado, cdTransacao);
                setEtapaAtual("TELEFONE");
                return false;
            } else {
                if(resultado === true) {
                    setEtapaAtual("TOKEN");
                    setTimeout(() => {
                        inputRefToken.current.focus();
                    }, 1);
                    return true;
                } else {
                    setEtapaAtual("TELEFONE");
                    return false;
                }
            }

        } catch (e) {

            console.log(e);
            notificacaoErroRequest(e, cdTransacao);
            return false;

        }

    }

    const verificaTelefone = async (params) => {

        try {

            const ffValues = formRef.current?.getFieldsValue();

            const telefone = ffValues.nrTelCelular.retiraCaracteres("() -");

            const payload = {
                telefone: telefone
            };

            let existeTel = false;

            const resposta = await postWS({
                sistema: 'smk',
                projeto: 'msiteimoblogin',
                acaoAExecutar: "existeTelefone",
                acaoWebAExecutar: "pesquisar",
            }, payload, WORKSPACE_LOADING_INDICATOR_AREA);

            resposta.mensagens.forEach(msg => {
                if(msg.id === "existeTel") {
                    existeTel = (msg.mensagem === "S") ;
                }
            });

            return existeTel;

        } catch (e) {
            console.log(e);
            notificacaoErroRequest(e, cdTransacao);
            return false;
        }

    }

    const solicitarEnvio = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return false;

            const telefoneExiste = await verificaTelefone();

            if(telefoneExiste === false) {
                notificacao.erro({message:"Não encontrado", description:"Telefone informado não encontrado no cadastro de usuários! Não é possivel fazer login por token."})
                return false;
            }

            await doSolitarToken();

            return true;

        } catch (e) {

            console.log(e);

            notificacaoErroValidForm(e, cdTransacao);

            return false;
        }

    }

    const solicitarReEnvio = async () => {

        try {

            const ffValues = formRef.current?.getFieldsValue();

            const telefone = ffValues.nrTelCelular.retiraCaracteres("() -");

            const payload = {
                contatoSms: telefone
            };

            const resposta = await postWS({
                sistema: 'sli',
                projeto: 'msiteimobconfsms',
                acaoWebAExecutar: "pesquisar",
                acaoAExecutar: "reenviarToken"
            }, payload);

            return true;

        } catch (e) {
            console.log(e);
            notificacaoErroRequest(e, cdTransacao);
            return false;
        }

    }

    const doValidarToken = async () => {

        try {

            const ffValues = formRef.current?.getFieldsValue();

            const token = ffValues.nrToken.retiraCaracteres("() -");

            const telefone = ffValues.nrTelCelular.retiraCaracteres("() -");

            console.log("token", token);

            const resultado = await validarTokenDigitado(telefone, token);

            if(resultado.data?.erro === true) {
                notificacaoErroRequest(resultado, cdTransacao);
                return false;
            } else {

                if(resultado.tokenValido === true) {

                    // resposta.CP
                    // resposta.cdPessoaUsr
                    // resposta.sessao

                    setEtapaAtual("TELEFONE");

                    onRequestClose();

                    onLogin({session: resultado.sessao});

                    return true;

                } else {
                    if(resultado.idErro === "naoEncontrado") {
                        notificacao.erro({message:"Opsss!", description:"Token inválido, confira os dados e tente novamente."})
                        return false;
                    } else if(resultado.idErro === "vencido") {
                        notificacao.erro({message:"Opsss!", description:"Token vencido! Por favor, solicite um novo."})
                        return false;
                    }
                }

            }

        } catch (e) {

            console.log(e);
            notificacaoErroRequest(e, cdTransacao);
            return false;

        }

    }

    const validarToken = async () => {

        try {

            await formRef?.current?.validateFields();

            const telaValida = await validaTela();

            if(telaValida === false) return false;

            await doValidarToken();

            return true;

        } catch (e) {
            console.log(e);
            notificacaoErroValidForm(e, cdTransacao);
            return false;
        }

    }

    const doSalvar = async () => {

        if(etapaAtual === "TELEFONE") {

            await solicitarEnvio();

        } else {

        }

    }

    const eBtnAcaoToken = (etapaAtual === "TELEFONE") ?
            <IY2BProFormButton
                width={"sm"}
                label={"Solicitar token"}
                onClick={() =>  solicitarEnvio()}
            />
        :
            <IY2BProFormButton
                width={"sm"}
                label={"Re-enviar token"}
                onClick={() =>  solicitarReEnvio()}
            />
    ;

    const eBtnValidar = (etapaAtual === "TOKEN") ?
            <IY2BProFormButton buttonClassName={" btn-validar-token-login "}
                width={"sm"}
                label={"Validar token"}
                onClick={() =>  validarToken()}
                />
        : null
    ;

    const eInputToken = (etapaAtual === "TOKEN") ?
            <IY2BProFormMasked
                className={"input-token"}
                width={"sm"}
                label={"Token recebido"}
                name="nrToken"
                tooltip = "Informe o token recebido"
                rules={[{ required: true, message: 'Por favor, informe o token recebido !' }, {
                    validator: async (rule, value) => {
                        if(inputRefToken) {
                            const result = await inputRefToken.current.validate();
                            if(result.valido === false) throw new Error(result.message);
                        }
                        return true;
                    }
                }]}
                api={inputRefToken}
                patterns={tokenPatterns}
            />
        : null;

    return (
        <IY2BReactModal
            left={MARGIN_LEFT}
            top={MODAL_CONTENT_TOP}
            width={MODAL_CONTENT_WIDTH}
            marginBottom={windowSize.heightMargin(PADRAO_MARGEM_POPUP.XXL)}
            exibePopUp={exibePopUp}
            onRequestClose={() => {
                setEtapaAtual("TELEFONE");
                onRequestClose();
            }}
            bodyOpenClassName={"login-com-token"}
            title={popupTitle}
            api={modalRefApi}
            body={
                <div className={"body-login-com-token"}>
                    <ProForm
                        formRef = { formRef }
                        isKeyPressSubmit = {true}
                        initialValues={{
                        }}
                        onFinish = { async ( ) => {
                            await doSalvar();
                        }}
                        submitter={{
                            // Completely customize the entire area
                            render : ( props , doms ) => {
                                return null;
                            }
                        }}
                        onValuesChange={async (changeValues, allValues) => {
                        }}
                        onFieldsChange = {(changedFields, allFields) => {
                        }}
                    >
                        <div className={"pro-form-group-spacer"}>
                            <Row gutter={[8,16]}>
                                <Col xs={{span:18}} md={{span:8}}>
                                    <IY2BProFormMasked
                                        width={"sm"}
                                        label={"Telefone celular"}
                                        name="nrTelCelular"
                                        tooltip = "Informe o nro. telefone celular para receber o token"
                                        rules={[{ required: true, message: 'Por favor, informe o nro do seu telefone celular !' }, {
                                            validator: async (rule, value) => {
                                                if(inputRefTelCel) {
                                                    const result = await inputRefTelCel.current.validate();
                                                    if(result.valido === false) throw new Error(result.message);
                                                }
                                                return true;
                                            }
                                        }]}
                                        api={inputRefTelCel}
                                        patterns={telCelPatterns}
                                        disabled={(etapaAtual === "TOKEN")}
                                    />
                                </Col>
                                <Col xs={{span:12}} md={{span:16}}>
                                    {eBtnAcaoToken}
                                    <IY2BProFormFiller />
                                </Col>
                            </Row>
                            <Row gutter={[8,16]}>
                                <Col xs={{span:12}} md={{span:8}}>
                                    {eInputToken}
                                </Col>
                                <Col xs={{span:24}} md={{span:16}}>
                                    {eBtnValidar}
                                    <IY2BProFormFiller />
                                </Col>
                            </Row>
                        </div>
                    </ProForm>
                </div>
            }
        />
    );

}

export default PopUpLoginComToken ;
