import CryptoJS from "./cryptoJS";

export class AesUtil {

    constructor(keySize, iterationCount) {
        this.keySize = keySize / 32;
        this.iterationCount = iterationCount;
    }

    generateKey(salt, passPhrase) {
        var key = CryptoJS.PBKDF2(
            passPhrase,
            CryptoJS.enc.Hex.parse(salt),
            {keySize: this.keySize, iterations: this.iterationCount});
        return key;
    }

    encrypt(salt, iv, passPhrase, plainText) {
        var key = this.generateKey(salt, passPhrase);
        var encrypted = CryptoJS.AES.encrypt(
            plainText,
            key,
            {iv: CryptoJS.enc.Hex.parse(iv)});
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    decrypt(salt, iv, passPhrase, cipherText) {
        var key = this.generateKey(salt, passPhrase);
        var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(cipherText)
        });
        var decrypted = CryptoJS.AES.decrypt(
            cipherParams,
            key,
            {iv: CryptoJS.enc.Hex.parse(iv)});
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

    getCipherText(plainText, passPhrase) {

        var iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
        var salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

        var aesCipherText = this.encrypt(salt, iv, passPhrase, plainText);

        var cipherText = btoa(iv + "::" + salt + "::" + aesCipherText);

        return cipherText;

    }

    getPlainText(cipherText, passPhrase) {

        var partsDecryptAes = atob(cipherText).split("::");

        var plainText = this.decrypt(partsDecryptAes[1], partsDecryptAes[0], passPhrase, partsDecryptAes[2]);

        return plainText;

    }
}






