import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import { Base64 } from 'js-base64';
import {transformData} from "./iy2b-react";
import {logoutProvedorIdentidade} from "./firebase";
import md5 from "blueimp-md5";
import {clearCache} from "./CacheRequestAPI";
import {AesUtil} from "../3rdpart/cripto/AesUtil";
import {WORKSPACE_LOADING_INDICATOR_AREA} from "../pages/Workspace/workspace";
import {postWS} from "./RequestAPI";
import {campoInformado} from "./iy2b-javascript";

function LoginAPI() {

    let userSession = null;
    let appConfig = null;
    let api = null;

    let store = null

    let debug = false;

    const aesUtil = new AesUtil(128, 1000);

    let loadingFn = function () { };

    const postProcessarPodPerfil = async (txCP, acaoAExecutar, usuarioAtual, hashNovaSenha) => {

        // POST http://localhost:8080/systemakers/suite/rest/podperfil/desenv/processarnegocio/plain?TXCONFIG=desenv&lm=1634761750400

        const url = "/suite/rest/podperfil/" + appConfig.txConfig + "/processarnegocio/plain";

        const agora = new Date();

        const payload = transformData({
            voip: agora.getTime(),
            acaoAExecutar: acaoAExecutar,
            acaoWebAExecutar: "ProcessarNegocioPerfil",
            usuarioAtual: usuarioAtual
        });

        const config = {
            headers: {
                "CP": txCP,
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Basic ' + hashNovaSenha
            }
        };

        try {

            const response = await api.post(url, payload, config);

            const data = response.data;

            if(data.erro !== true) {

                //console.log(data);

            }

            return data;

        } catch (error) {

            if(error.data !== undefined) {

                return error.data;

            } else {

                throw error;

            }

        }

    }

    const postWS = async ({acaoWebAExecutar, acaoAExecutar, sistema, projeto}, payload) => {

        const agora = new Date();

        const servico = "/" + sistema + "/rest/" + projeto + "/post/" + acaoWebAExecutar + "/" + acaoAExecutar + "/" + appConfig.txConfig + "/" + appConfig.wsToken + "/endpoint"
            + "?lm=" + agora.getTime();

        payload.txIdApp = appConfig.txIdApp;

        const config = {
            headers: {
                'Content-Type' : 'application/json',
                "html5-agent": "S",
                "jqsSMK": "S"
            }
        };

        try {

            loadingFn(true);

            const response = await api.post(servico, payload, config);

            loadingFn(false);

            const data = response.data;

            if(data.erro !== true) {

                //console.log(data);

            } else {

                if(data.key === "invalidSession") {

                    const refreshResp = await this.refreshSession();

                    if(refreshResp === true) {

                        const secondTry = await this.post(servico, payload);

                        return secondTry.data;

                    } else {

                        throw new Error("nao consegui pegar novo token (post)");

                    }

                }

            }

            return data;

        } catch (error) {

            loadingFn(false);

            throw error;

        }

    };

    const senhaDebugAtual = () => {

        const hoje = new Date();

        const  anoMes = hoje.format("YYYYMM");

        const hashAnoMes = md5(anoMes);

        const sMes = anoMes.substring(4); // mes do ano mes

        const mes = parseInt(sMes); // mes inteiro

        let posicaoInicial = mes * 2; // de acordo com o mes

        posicaoInicial --; // volta a posicao inicial do par: 12, 34, 56, 78, ...

        posicaoInicial --; // o java começa em ZERO, 01, 23, 45, 67, ...

        const senhaAnoMes = hashAnoMes.substring(posicaoInicial, posicaoInicial+2);

        return senhaAnoMes.toUpperCase();

    }

    const _executaLogin_ = async (cdProvedorIdentidade, hash, debugLigado=false) => {

        const url = "/suite/rest/login/" + appConfig.txConfig + "/processarnegocio/plain";

        const agora = new Date();

        const payload = transformData({
            voip: agora.getTime(),
            executaLogin: 'S',
            acaoWebAExecutar: 'ProcessarNegocioLogin',
            acaoAExecutar: (cdProvedorIdentidade===0)?'executaLogin':'executaLoginProvId',
            tipoResposta: 'reduzida',
            "debug-authorization": (debugLigado===true && cdProvedorIdentidade > 0) ? senhaDebugAtual() : undefined
        });

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Basic ' + hash
            }
        };

        if(cdProvedorIdentidade > 0) {
            config.headers.idProvider = cdProvedorIdentidade;
        }

        try {

            const response = await api.post(url, payload, config);

            const data = response.data;

            if(data.erro !== true) {

                // console.log(data);

                data.modeloPadrao.cdProvedorIdentidade = cdProvedorIdentidade;
                data.modeloPadrao.loginHash = hash;

                clearCache();

            }

            return data;

        } catch (error) {

            if(error.data !== undefined) {

                return error.data;

            } else {

                throw error;

            }

        }

    }

    const executaLogin = async (hash, debugLigado=false) => {
        return await _executaLogin_ (0, hash, debugLigado);
    }

    const executaLoginProvId = async (cdProvedorIdentidade, hash, debugLigado=false) => {
        return await _executaLogin_ (cdProvedorIdentidade, hash, debugLigado);
    }

    return {

        setLoading: function(value) {
            loadingFn = value;
        },

        setDebug: function(value) {
            debug = (value === true);
        },

        // X-XSRF-TOKEN para o access_token

        setStore: function (value) {

            if(store == null) {

                if(debug) console.log(value);

                store = value;

            }

        },

        setAppConfig: function(value) {

            if(debug) console.log(value);

            appConfig = value;

            api = axios.create({
                baseURL: appConfig.host + "/systemakers",
                responseType: 'json',
                useXDomain: true,
                timeout: (60 * 1000) * 5
            });

            // api.defaults.headers.post['Content-Type'] = 'application/json';

            api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

            delete api.defaults.headers.common['X-Requested-With'];

            // api.defaults.useXDomain = true;
            // api.defaults.timeout = 45 * 1000;
            // api.defaults.headers.common['Authorization'] = AUTH_TOKEN;

            api.interceptors.response.use(function (response) {
                if(response["status"]==200) {
                    if(response["statusText"] == "OK" || response["statusText"] == ""|| response["statusText"] == "200" || response["statusText"] == undefined) {
                        if(response.config.headers["iy2b-post"] == "S") {
                            if(response.data.mensagens == undefined) {
                                var data = response.data;
                                response.data = {
                                    mensagens: [data]
                                };
                            }
//                console.log(response.data);
                        }
                    }
                }
                return response;
            }, function (rejection) {

                if(rejection.response) {

                    switch (rejection.response.status) {
                        case 401:
                            console.log('login:authExpired');
                            break;
                        case 404:
                        case 403:
                            console.log(rejection.response);
                            break;
                        case 502:
                        case 503:
                            console.log(rejection.response);
                            break;
                    }

                    if(rejection.response.data !== undefined) {

                        if (typeof rejection.response.data === 'string' || rejection.response.data instanceof String) {

                        } else {
                            rejection.response.data.erro = true;
                        }

                    }

                    return Promise.reject(rejection.response);

                } else {

                    return Promise.reject({status:504, statusText: "Network Error !", data:""});

                }

            });

        },

        solicitaNovoStartToken: async function () {

            const url =  "/oauth/token";

            const payload = {
                "type": "startKey"
            };

            try {

                const response = await api.post(url, payload);

                const data = response.data;

                if(data.erro !== true) {

                    console.log(data);

                    return data.key;

                } else {

                    return data;

                }

            } catch (error) {

                throw error;

            }

        },

        solicResetSenha: async function (usuario) {

            //* http://servidor:porta/systemakers/SMK/rest/wscadsolicsenhausr/post/{ACAOWEB}/acaoAExecutar/config/token/endpoint

            const payload = {
                "noLogin": usuario,
                "origin": window.location.origin
            };

            try {

                const response = await postWS({
                    acaoWebAExecutar: "processarnegocio",
                    acaoAExecutar: "solicitar",
                    sistema: "smk",
                    projeto: "wscadsolicsenhausr"
                }, payload);

                return response;

            } catch (error) {

                if(error.data !== undefined) {

                    return error.data;

                } else {

                    throw error;

                }

            }

        },

        efetuaLogout: async function () {

            // http://erp.iy2b.com/systemakers/suite/suite.processarnegocio.smk?TXCONFIG=asp-recsu&lm=1634230766969

            // const url = "/suite/rest/login/" + appConfig.txConfig + "/processarnegocio/plain";

            // ??? fazer o autologin do PI funcionar NOT, por enquanto, 2 autologin não funcionam

            const respLogout = await logoutProvedorIdentidade();

            console.log(userSession);

            const agora = new Date();

            const url = "/suite/suite.processarnegocio.smk?TXCONFIG=" + appConfig.txConfig + "&lm=" + agora.getTime();

            const payload = transformData({
                "acaoWebAExecutar": "ProcessarNegocioUsuario",
                "acaoAExecutar": "executaLogout",
                "CP": userSession.txCP,
                "jqsSMK": "S"
            });

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            try {

                const response = await api.post(url, payload, config);

                const data = response.data;

                if(data.erro !== true) {
                    console.log(data);
                }

                return data;

            } catch (error) {

                if(error.data !== undefined) {

                    return error.data;

                } else {

                    throw error;

                }

            }

        },

        renovaLogin: async function(hash, cdProvedorIdentidade) {

            if(cdProvedorIdentidade === 0) {

                return await executaLogin(hash);

            } else {

                return await executaLoginProvId(cdProvedorIdentidade, hash);

            }

        },

        efetuaLogin: async function(usuario, senha, debugLigado=false) {

            const srcEncode = usuario.toLowerCase().trim() + ':' + senha.trim() + ((debugLigado===true)? "'" + senhaDebugAtual() : "");

            const hash = Base64.encode(srcEncode);

            return await executaLogin(hash, debugLigado);

        },

        efetuaLoginProvId: async function (cdProvedorIdentidade, chUId, noEmail, debugLigado=false) {

            const hash = Base64.encode(noEmail.toLowerCase().trim() + ':' + chUId.trim());

            return await executaLoginProvId(cdProvedorIdentidade, hash, debugLigado);

        },

        setSession: function (value) {

            userSession = value;

            if(debug) console.log(userSession);

        },

        validarTokenDigitado: async function (telefone, token) {

            try {

                // validar token

                const postConfig = {
                    "iy2b-post": "S",
                    headers: {
                        'html5-agent': "S"
                    }
                };

                const agora = new Date();

                const qs = "lm=" + agora.getTime();

                const payload = transformData({
                    ACK: appConfig.wsToken,
                    acaoWebAExecutar: 'ProcessarNegocioLogin',
                    acaoAExecutar: 'validarToken',
                    tipoResposta: 'reduzida',
                    nrTelCelular: "+55" + telefone,
                    txToken: token
                });

                const url = "/suite/rest/login/" + appConfig.txConfig + "/processarnegocio/plain?" + qs;

                const response = await api.post(url, payload, postConfig);

                const resposta = {
                    tokenValido: false,
                    idErro: ""
                };

                response.data.mensagens.forEach(msg => {

                    if(msg.id === 'Ok') {

                        resposta.tokenValido = true;

                        resposta.cdPessoaUsr = msg.modeloPadrao.cdPessoaUsr;

                    } else if(msg.id === "respOcp") {

                        const oCP = msg.mensagem;

                        const plainCP = aesUtil.getPlainText(oCP, appConfig.txConfig + "," + agora.format("MMDDYYYY"));

                        resposta.CP = plainCP;

                    } else if(msg.id === 'naoEncontrado'){

                        resposta.idErro = msg.id;

                        // $scope.alertas.push({ type:'danger', msg:'Token inválido, confira os dados e tente novamente', timeout: delayTimeoutAlertDefault });

                    } else if(msg.id === 'vencido'){

                        resposta.idErro = msg.id;

                        // $scope.alertas.push({ type:'danger', msg:'Token vencido! Por favor, solicite um novo.', timeout: delayTimeoutAlertDefault });

                    }

                });

                if(resposta.tokenValido === true) {

                    const doVerificaUsuario = async () => {

                        const payload = {
                            telefone: telefone,
                            cdPessoaUsr: resposta.cdPessoaUsr
                        };

                        const response = await postWS({
                            sistema: 'smk',
                            projeto: 'msiteimoblogin',
                            acaoWebAExecutar: "processarnegocio",
                            acaoAExecutar: "verificarUsuario"
                        }, payload);

                        console.log(response.mensagens);

                        response.mensagens.forEach(mensagem => {

                            if(mensagem.id === "pkUsuario") {

                                if(campoInformado(mensagem.modeloPadrao.nrTelCelular)===false) {
                                    mensagem.modeloPadrao.nrTelCelular = telefone;
                                }

                                resposta.sessao = { ...mensagem.modeloPadrao,
                                    cdPessoaEmpAtual: mensagem.modeloPadrao.cdPessoaEmp,
                                    txCP: resposta.CP,
                                    txConfig: appConfig.txConfig,
                                    flgDebug: "N",
                                    flgLogin2FA: "N",
                                    noPessoa: mensagem.modeloPadrao.noPessoaUsr
                                };

                            }

                        });

                    }

                    await doVerificaUsuario();

                }

                return resposta;

            } catch (error) {
                console.log(error);
                return error;
            }

        },

        solicitarEnvioToken: async function (telefone) {

            // 1 - criar chave post

            const agora = new Date();

            const getConfig = {
                "iy2b-get": "S",
                headers: {
                }
            };

            const qs = "CHAMADOR=JAVA&" +
                "TXCONFIG=" + appConfig.txConfig + "&" +
                "versaoHTML=S&" +
                "respOcp=" + agora.getTime();

            const url = "suite/suite.inicapp.smk?" + qs;

            try {

                const response = await api.get(url, getConfig);

                const data = response.data;

                if(data.erro !== true) {

                    const plainCP = aesUtil.getPlainText(data.cp, appConfig.txConfig + "," + agora.format("MMDDYYYY"));

                    // 2 - enviar token

                    const doEnviarToken = async () => {

                        try {

                            const postConfig = {
                                "iy2b-post": "S",
                                headers: {
                                    'html5-agent': "S"
                                }
                            };

                            const qs = "lm=" + agora.getTime();

                            const payload = transformData({
                                ACK: appConfig.wsToken,
                                acaoWebAExecutar: 'ProcessarNegocioLogin',
                                acaoAExecutar: 'enviarToken',
                                tipoResposta: 'reduzida',
                                validTokenPendente: 5,
                                nrTelCelular: "+55" + telefone
                            });

                            const url = "/suite/rest/login/" + appConfig.txConfig + "/processarnegocio/plain?" + qs;

                            const response = await api.post(url, payload, postConfig);

                            if(response.data?.id === "ok") {
                                return true;
                            } else {
                                console.log("tentar novamente doEnviarToken", response.data);
                                return await doEnviarToken();
                            }

                        } catch (error) {
                            console.log(error);
                            return error;
                        }

                    }

                    return await doEnviarToken();

                }

                return false;

            } catch (error) {

                if(error.data !== undefined) {

                    return error;

                } else {

                    throw error;

                }

            }

        },

        alteraFotoUsuario: async function (usuario, {strImg64, extensao}) {

            const payload = transformData({
                acaoWebAExecutar: 'ProcessarNegocioPerfil',
                acaoAExecutar: 'alteraImagemUsuario',
                strImg64: strImg64,
                extensao: extensao
            });

            // POST http://localhost:8080/systemakers/suite/rest/podperfil/desenv/processarnegocio/plain

            const agora = new Date();

            const url = "/suite/rest/podperfil/" + appConfig.txConfig + "/processarnegocio/plain?lm=" + agora.getTime();

            const config = {
                headers: {
                    "CP": usuario.txCP,
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            };

            try {

                const response = await api.post(url, payload, config);

                const data = response.data;

                if(data.erro === true) {

                    console.log(data);

                }

                return data;

            } catch (error) {

                if(error.data !== undefined) {

                    return error.data;

                } else {

                    throw error;

                }

            }

        },

        alteraSenhaUsuario: async function (usuario, novaSenha) {

            const usuarioAtual = {
                "remoteClass":"br.com.systemakers.modelo.smk.Usuario",
                "flgAtivo":"S",
                "noLogin": usuario.noLogin.toUpperCase(),
                "senha": "Basic " + usuario.loginHash,
                "cdPessoaUsr": {
                    "remoteClass":"br.com.systemakers.modelo.sgr.Pessoa",
                    "cdPessoa": usuario.cdPessoaUsr
                }
            };

            const hashNovaSenha = Base64.encode(usuario.noLogin.toLowerCase().trim() + ':' + novaSenha.trim());

            // 1 - valida requisitos

            const resultadoValidar = await postProcessarPodPerfil(usuario.txCP, "validarRequisitosSenha", usuarioAtual, hashNovaSenha);

            if(resultadoValidar.id === "senhaValida") {

                // 2 - alterar senha

                const resultadoTrocar = await postProcessarPodPerfil( usuario.txCP, "alteraSenhaLogin", usuarioAtual, hashNovaSenha);

                return resultadoTrocar;

            } else {

                return resultadoValidar;

            }

        }

    }

}

const api = new LoginAPI();

////const WORKSPACE_LOADING_INDICATOR_AREA = "workspace-area";

export function useStore (value) {
    api.setStore(value);
}

export function useAppConfig (value) {
    api.setAppConfig(value);
}

export function useUserSession (value) {
    api.setSession(value);
}

export async function solicResetSenha (usuario) {
    return await trackPromise ( api.solicResetSenha(usuario) );
}

export async function alteraFotoUsuario (usuario, mediaParams) {
    return await trackPromise ( api.alteraFotoUsuario(usuario, mediaParams), WORKSPACE_LOADING_INDICATOR_AREA );
}

export async function alteraSenhaUsuario (usuario, novaSenha, trackArea) {
    if(trackArea != undefined && trackArea != null) {
        return await trackPromise ( api.alteraSenhaUsuario(usuario, novaSenha), trackArea );
    } else {
        return await trackPromise ( api.alteraSenhaUsuario(usuario, novaSenha) );
    }
}

export async function efetuaLogout () {
    return await trackPromise ( api.efetuaLogout(), WORKSPACE_LOADING_INDICATOR_AREA );
}

export async function renovaLogin (hash, cdProvedorIdentidade) {
    return await trackPromise ( api.renovaLogin(hash, cdProvedorIdentidade), WORKSPACE_LOADING_INDICATOR_AREA );
}

export async function efetuaLogin (usuario, senha, debugLigado=false) {
    return await trackPromise ( api.efetuaLogin(usuario, senha, debugLigado) );
}

export async function efetuaLoginProvId (cdProvedorIdentidade, chUId, noEmail, debugLigado=false) {
    return await trackPromise ( api.efetuaLoginProvId(cdProvedorIdentidade, chUId, noEmail, debugLigado) );
}

export async function solicitarEnvioToken (telefone) {
    return await trackPromise ( api.solicitarEnvioToken(telefone) );
}

export async function validarTokenDigitado (telefone, token) {
    return await trackPromise ( api.validarTokenDigitado(telefone, token) );
}
