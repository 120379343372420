import "./style.scss";

import {Button} from "antd";
import React from "react";
import {CUSTOM} from "../../../assets/iconografia";
import PopUpLoginComToken from "../../PopUpLoginComToken/popUpLoginComToken";

const iconProvedor = CUSTOM.SQUARE_BINARY_ICON;

const BotaoLoginToken = ( props ) => {

    const { block=true, disabled=false, onLogin } = props;

    const [exibeLogin, setExibeLogin] = React.useState(false);

    const title = "Log in com token de verificação";

    const onClick = async () => {
        setExibeLogin (true);
    };

    const boxClassName = [];

    return (
        <>
            <div className={boxClassName.toClassName()}>
                <Button className={"token"} type={"default"} onClick={onClick} icon={iconProvedor} block={block}
                        disabled={disabled}>&nbsp;{title}</Button>
            </div>
            <PopUpLoginComToken
                           exibePopUp={exibeLogin}
                           onRequestClose={() => {
                               setExibeLogin(false);
                           }}
                           onLogin={onLogin}
            />
        </>
    );

}

export default BotaoLoginToken;
