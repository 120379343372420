import {Form} from "antd";
import InputMasked from "./InputMasked";
import React from "react";
import {ProForm} from '@ant-design/pro-components' ;
import {campoInformado} from "../lib/iy2b-javascript";

const IY2BProFormMasked = (props) => {

    const { className } = props;

    const myClassName = ["iy2b-pro-field", "pro-field"];

    if(props.width) {
        myClassName.push("pro-field-" + props.width);
    }

    if(campoInformado(className) === true) {
        myClassName.addAll(className.split(" "));
    }

    return (
        <ProForm.Item
            label = {props.label}
            name = {props.name}
            tooltip = {props.tooltip}
            rules = {props.rules}
        >
            <InputMasked api = {props.api}
                         patterns = {props.patterns}
                         disabled = {props.disabled}
                         className = {myClassName.toClassName()}
            />
        </ProForm.Item>
    );

}

export default IY2BProFormMasked;
